import * as React from "react";
import { useEffect } from "react";
import { navigate } from "@reach/router";

const redirectToHome = () => {
	navigate("/");
};

const NotFoundPage = () => {
	useEffect(redirectToHome, []);

	return null;
};

export default NotFoundPage;
